import {
  ArrowRightIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import { AnimatePresence, motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState, useRef } from "react";
import Button from "../../components/button";
import BingMapsReact from "bingmaps-react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NavMenu from "../../components/nav-menu";
import Solutions from "../../components/solutions";
import { messages } from "../../i18n-translations";

const ContactPage: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navTopMargin, setNavTopMargin] = React.useState(40);
  const header = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenLg = breakpoints.lg;
  const isScreenMd = breakpoints.md;

  useEffect(() => {
    if (header.current) setNavTopMargin(header.current.clientHeight);
  }, [header]);

  let map = (
    <BingMapsReact
      pushPins={[
        {
          center: {
            latitude: 29.382658806418426,
            longitude: 121.43882045767118,
          },
          options: {
            title: "宁波锦泰橡塑有限公司",
          },
        },
      ]}
      bingMapsKey="Ar9cGdZ2ZyZGXMOEyUk4Lj9NHcfeVbRzNJeS5PaWI6cWt7keTUsbMyapE17v1w9R"
      mapOptions={{
        disableMapTypeSelectorMouseOver: true,
      }}
      viewOptions={{
        center: {
          latitude: 29.382658806418426,
          longitude: 121.43882045767118,
        },
        zoom: 15,
      }}
    />
  );

  return (
    <main className="relative z-0 w-screen bg-white">
      <div ref={header} className="fixed top-0 w-full bg-white z-110">
        <Header
          isNavOpen={isNavOpen}
          onMenuButtonPress={() => setIsNavOpen(!isNavOpen)}
          cn
        />
      </div>
      {
        //#region Mobile Nav
      }
      {!isScreenXl && (
        <AnimatePresence>
          {isNavOpen && (
            <div
              className={`fixed flex inset-0 z-101 w-screen h-screen bg-transparent`}
            >
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex w-full origin-top"
                key="nav"
                style={{ marginTop: navTopMargin }}
              >
                <NavMenu cn />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      )}
      {
        //#endregion
      }

      {
        //#region Header Image
      }
      <div style={{ marginTop: navTopMargin }} className="bg-white">
        <div className="relative">
          <div className="z-0 w-full h-full">
            {isScreenLg ? (
              <StaticImage
                src={"../../images/resources-header.webp"}
                alt="Contact Us"
                layout="fullWidth"
              />
            ) : (
              <StaticImage
                src={"../../images/resources-header-md.webp"}
                alt="Contact Us"
                layout="fullWidth"
              />
            )}
          </div>
          <div className="absolute inset-0 z-0 flex flex-col items-center justify-center text-white bg-black opacity-80">
            <h1 className="text-5xl">{messages.cn.contactUs}</h1>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      <div
        className="py-10 mx-auto bg-white sm:px-10 lg:px-20 xl:container lg:py-14"
        id="location"
      >
        <div className="w-full text-center">
          <div className="px-6 md:px-0">
            <div className="pb-4 text-2xl">{messages.cn.officeContact}</div>
            <div className="pb-6">
              {messages.cn.contactUsGeographicalAdvantage}
            </div>
          </div>
          {!isScreenLg && <div className="w-full h-80">{map}</div>}
        </div>

        <div className="flex flex-col items-stretch w-full text-center mb-14 md:px-6 lg:px-0 lg:flex-row lg:container lg:mx-auto lg:mt-6 md:shadow-xl">
          <div className="flex flex-col items-center w-full bg-white md:w-full lg:w-1/2 lg:items-start lg:pl-20 lg:h-full lg:justify-center md:py-6">
            <div className="items-center lg:flex lg:pb-12">
              <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                <PhoneIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
              </div>
              <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                <div className="text-lg text-blue-600">+86 574 65292958</div>
                <div>{messages.cn.phoneNumber}</div>
              </div>
            </div>

            <div className="items-center lg:flex lg:pb-12">
              <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                <MailIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
              </div>
              <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                <div className="text-lg text-blue-600">info@jintai.com</div>
                <div>{messages.cn.emailAddress}</div>
              </div>
            </div>

            <div className="items-center lg:flex">
              <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                <LocationMarkerIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
              </div>
              <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                <div className="mb-1 text-lg leading-tight text-blue-600">
                  {messages.cn.meilinIndustrialZone},
                  <br />
                  {messages.cn.ningboZhejianChina}
                </div>
                <div>{messages.cn.companyLocation}</div>
              </div>
            </div>
          </div>
          {isScreenLg && <div className="relative w-1/2">{map}</div>}
        </div>
        <Solutions cn />
      </div>

      <div className="flex justify-center w-full py-10 bg-gray-100" id="form">
        <div className="px-6 my-8 md:w-full lg:w-2/3 ">
          <div className="mb-6 text-2xl">{messages.cn.fillInTheForm}</div>
          <div className="mx-1 mb-8">{messages.cn.fillInTheFormText}</div>
          <form name="contact" method="POST" data-netlify="true" action="/">
            <input type="hidden" name="form-name" value="contact" />
            <div className="gap-5 md:grid md:grid-cols-2">
              <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                <label htmlFor="name" className="sr-only">
                  {messages.cn.name}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 placeholder:content-['_↗']"
                  placeholder={messages.cn.name}
                  required
                />
              </div>
              <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                <label htmlFor="company" className="sr-only">
                  {messages.cn.company}
                </label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 placeholder:content-['_↗']"
                  placeholder={messages.cn.company}
                  required
                />
              </div>
              <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                <label htmlFor="email" className="sr-only">
                  {messages.cn.email}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 placeholder:content-['_↗']"
                  placeholder={messages.cn.emailAddress}
                  required
                />
              </div>
              <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                <label htmlFor="phone" className="sr-only">
                  {messages.cn.phoneNumber}
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 placeholder:content-['_↗']"
                  placeholder={messages.cn.phoneNumber}
                  required
                />
              </div>
              <div className="mb-8 md:col-span-2 md:row-span-1 md:mb-4">
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium text-gray-700 sr-only"
                >
                  {messages.cn.howCanWeHelp}
                </label>
                <textarea
                  rows={!isScreenXl && !isScreenMd ? 4 : 6}
                  name="comment"
                  id="comment"
                  className="block w-full p-3 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 md:text-base"
                  placeholder={messages.cn.howCanWeHelp}
                />
              </div>
              <Button
                submit
                big
                className="w-full py-6 text-xl md:py-3 md:w-9/12 lg:col-span-2 lg:w-full"
              >
                {messages.cn.submit}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Footer cn />
    </main>
  );
};

export default ContactPage;
